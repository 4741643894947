import React from 'react';
import { Helmet } from 'react-helmet-async';
import { usePageTracking } from '../hooks/usePageTracking';

export default function HowTo() {
    usePageTracking('How To Guide');
    return (
        <>
            <Helmet>
                <title>How to Use - Check-In Questions</title>
                <meta name="description" content="Learn how to effectively use Check-In Questions in your meetings and workshops. Get practical tips for generating engaging questions, energizers, and icebreakers." />
                <meta property="og:title" content="How to Use - Check-In Questions" />
                <meta property="og:description" content="Learn how to effectively use Check-In Questions in your meetings and workshops. Get practical tips for generating engaging questions, energizers, and icebreakers." />
            </Helmet>
            <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
                <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
            <h1 className="text-3xl font-bold mb-6">How to Use Check-In Questions in Your Meetings and Workshops</h1>
                    
                    <p className="mb-8 text-gray-700">
                        Using Check-In Questions for meetings and workshops is easy and impactful. By generating prompts directly on the site, you can set the right tone, encourage engagement, and create a collaborative atmosphere tailored to your goals. Here's how to make the most of these tools throughout your session.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">1. Starting with AI-Generated Check-In Questions</h3>
                    <p className="mb-8 text-gray-700">
                        Begin by generating a check-in question on our website to set the tone for your session. Each prompt is unique and crafted to help participants connect with one another. Start with questions like, "What's something you're feeling motivated about today?" or "What's one challenge you're currently facing?" This step lets everyone focus and build rapport, creating an immediate sense of connection.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">2. Building Momentum with Icebreakers and Energizers</h3>
                    <p className="mb-8 text-gray-700">
                        Use the platform to generate an energizing activity or icebreaker question to break down barriers and increase energy. Fun prompts like "two truths and a lie" or light-hearted questions help establish comfort among participants. This is especially helpful for new teams or sessions where participants need a quick reset between topics. Generating these activities on the spot keeps the energy high and the interactions fresh.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">3. Using Prompted Reflection During Breaks</h3>
                    <p className="mb-8 text-gray-700">
                        For longer sessions, use Check-In Questions to generate reflective prompts during breaks, helping participants stay engaged. Questions like "What's one key insight you've gained so far?" keep discussions relevant and give everyone time to process what they're learning. Reflection prompts add a thoughtful layer to your session, encouraging people to contribute more deeply as the session unfolds.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">4. Facilitating Group Discussions with AI-Powered Questions</h3>
                    <p className="mb-8 text-gray-700">
                        Generate targeted questions directly on the site to guide group discussions and inspire deeper insights. Use prompts such as, "What are some perspectives we haven't yet considered?" or "What's one bold idea we should explore?" These questions create a safe space for everyone to share ideas, promoting inclusivity and encouraging thoughtful dialogue.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">5. Closing with Check-Out Questions</h3>
                    <p className="mb-8 text-gray-700">
                        Conclude your session by generating a check-out question to help participants reflect on their experience. A prompt like "What's one takeaway you're excited to apply?" or "How has your perspective shifted today?" allows everyone to leave with a sense of closure and a clear focus. This final step reinforces the session's impact and lets participants share their thoughts before wrapping up.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">6. Customizing Prompts for Your Goals</h3>
                    <p className="mb-8 text-gray-700">
                        Tailor the AI-generated prompts to match specific session objectives, such as team alignment, brainstorming, or personal development. For example, select prompts that encourage deeper reflection if your goal is team alignment, or choose lighter, curiosity-driven questions for a more relaxed, creative session. This on-the-fly customization keeps your questions both engaging and strategically relevant.
                    </p>

                    <h3 className="text-2xl font-bold mb-4">Maximizing Impact with Real-Time Prompts</h3>
                    <p className="text-gray-700">
                        Whether it's a brief check-in or a series of activities, generating questions in real time keeps your session dynamic and responsive. Check-In Questions empowers you to adjust tone, depth, and style instantly, creating an atmosphere where everyone feels valued and ready to contribute meaningfully. Start using Check-In Questions today to bring fresh energy and purpose to every meeting or workshop!
                    </p>
                </div>
            </div>
        </>
    );
}
