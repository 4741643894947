import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../contexts/SubscriptionContext';
import { ArrowRight } from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const { user, supabase } = useAuth();
  const { subscriptionStatus } = useSubscriptionStatus();
  const [isEditingName, setIsEditingName] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [name, setName] = useState(user?.user_metadata?.full_name || '');
  const [newPassword, setNewPassword] = useState('');
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteError, setDeleteError] = useState('');
  const [updateError, setUpdateError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Current subscription status:', subscriptionStatus);
  }, [subscriptionStatus]);

  useEffect(() => {
    async function fetchSavedQuestions() {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('saved_questions')
          .select('*')
          .eq('user_id', user.id);

        if (error) {
          console.error('Error fetching saved questions:', error);
          return;
        }

        setSavedQuestions(data || []);
      } catch (error) {
        console.error('Error in fetchSavedQuestions:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchSavedQuestions();
  }, [user, supabase]);

  const handleManageSubscription = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('create-billing-portal-session', {
        body: { return_url: window.location.href }
      });

      if (error) throw error;
      if (!data?.url) throw new Error('No portal URL returned from server');

      window.location.href = data.url;
    } catch (err) {
      console.error('Error redirecting to billing portal:', err);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      window.location.href = '/';
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }

    try {
      // First, delete user data from saved_questions
      const { error: deleteQuestionsError } = await supabase
        .from('saved_questions')
        .delete()
        .eq('user_id', user.id);

      if (deleteQuestionsError) throw deleteQuestionsError;

      // Instead of using admin.deleteUser, we'll use user.delete()
      const { error: deleteUserError } = await supabase.auth.api.deleteUser(user.id);
      if (deleteUserError) throw deleteUserError;

      // Sign out and redirect to home
      await supabase.auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting account:', error);
      setDeleteError('Failed to delete account. Please try again or contact support.');
    }
  };

  // Helper function to determine subscription status
  const isPremium = () => {
    if (!subscriptionStatus) return false;
    
    // Check if subscription exists and is active
    const isActive = subscriptionStatus.status === 'active' || 
                    subscriptionStatus.subscription_status === 'premium';
    
    // Check if subscription hasn't expired
    const hasNotExpired = subscriptionStatus.current_period_end && 
                         new Date(subscriptionStatus.current_period_end * 1000) > new Date();
    
    return isActive && hasNotExpired;
  };

  // Handle name change
  const handleNameChange = async () => {
    try {
      const { error } = await supabase.auth.updateUser({
        data: { full_name: name }
      });

      if (error) throw error;
      setIsEditingName(false);
    } catch (error) {
      console.error('Error updating name:', error);
      setUpdateError('Failed to update name');
    }
  };

  // Handle password change
  const handlePasswordChange = async () => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;
      setShowPasswordInput(false);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      setUpdateError('Failed to update password');
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
        <h1 className="text-3xl font-bold mb-6">Profile</h1>
          <p className="text-gray-500 text-center py-8">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
        <div className="mx-auto">
          <h1 className="text-3xl font-bold mb-6">Profile</h1>

          {/* User Info and Stats */}
          <div className="mb-8 space-y-2">
            <p className="text-lg"><span className="text-gray-500">Name:</span> {name || 'No name set'}</p>
            <p className="text-lg"><span className="text-gray-500">Email:</span> {user.email}</p>
            <p className="text-lg"><span className="text-gray-500">Member since:</span> {new Date(user.created_at).toLocaleDateString()}</p>
            {/* <p className="text-lg"><span className="text-gray-500">Questions Generated:</span> {savedQuestions.length}</p> */}
          </div>

          {/* Subscription Section */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Subscription</h2>
            <div className="space-y-2 mb-4">
              <p className="text-lg">
                <span className="text-gray-500">Current Plan:</span>
                <span className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm font-medium ml-2">
                  {subscriptionStatus?.subscription_status === 'premium' ? 'Premium' : 'Free'}
                </span>
              </p>
            </div>
            {subscriptionStatus?.subscription_status === 'premium' ? (
              <button
                onClick={handleManageSubscription}
                className="w-full bg-blue-600 text-white rounded-lg px-4 py-2 hover:bg-blue-700 transition-colors"
              >
                Manage Subscription
              </button>
            ) : (
              <button
                onClick={() => navigate('/pricing')}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-xl text-lg transition duration-200 ease-in-out transform hover:translate-y-[-2px] disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
              >
                Learn about Premium
              </button>
            )}
          </div>

          {/* Account Settings */}
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Account Settings</h2>
            
            {/* Name Change */}
            {isEditingName ? (
              <div className="space-y-4 bg-gray-50 rounded-lg p-4">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter new name"
                />
                <div className="flex space-x-4">
                  <button
                    onClick={handleNameChange}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setIsEditingName(false)}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => setIsEditingName(true)}
                className="w-full text-left bg-gray-50 rounded-lg px-4 py-3 hover:bg-gray-100 transition-colors flex justify-between items-center"
              >
                Change Name
                <ArrowRight className="h-5 w-5 text-gray-400" />
              </button>
            )}

            {/* Password Change */}
            {showPasswordInput ? (
              <div className="space-y-4 bg-gray-50 rounded-lg p-4">
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter new password"
                />
                <div className="flex space-x-4">
                  <button
                    onClick={handlePasswordChange}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setShowPasswordInput(false);
                      setNewPassword('');
                    }}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => setShowPasswordInput(true)}
                className="w-full text-left bg-gray-50 rounded-lg px-4 py-3 hover:bg-gray-100 transition-colors flex justify-between items-center"
              >
                Change Password
                <ArrowRight className="h-5 w-5 text-gray-400" />
              </button>
            )}

            {/* Sign Out Button */}
            <button
              onClick={handleSignOut}
              className="w-full text-left bg-gray-50 rounded-lg px-4 py-3 hover:bg-gray-100 transition-colors flex justify-between items-center"
            >
              Sign Out
              <ArrowRight className="h-5 w-5 text-gray-400" />
            </button>

            {/* Delete Account */}
            {confirmDelete ? (
              <div className="space-y-4">
                <p className="text-red-600">Are you sure you want to delete your account? This action cannot be undone.</p>
                {deleteError && <p className="text-red-600">{deleteError}</p>}
                <div className="flex space-x-4">
                  <button
                    onClick={handleDeleteAccount}
                    className="w-1/2 bg-red-600 text-white rounded-lg px-4 py-3 hover:bg-red-700 transition-colors"
                  >
                    Yes, Delete Account
                  </button>
                  <button
                    onClick={() => setConfirmDelete(false)}
                    className="w-1/2 bg-gray-200 text-gray-800 rounded-lg px-4 py-3 hover:bg-gray-300 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => setConfirmDelete(true)}
                className="w-full text-left bg-red-50 text-red-600 rounded-lg px-4 py-3 hover:bg-red-100 transition-colors flex justify-between items-center"
              >
                Delete Account
                <ArrowRight className="h-5 w-5 text-red-400" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 