import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackEvent } from '../lib/analytics';

export const usePageTracking = (pageName) => {
  const location = useLocation();

  useEffect(() => {
    trackEvent('page_view', {
      page_name: pageName,
      page_path: location.pathname,
      page_url: window.location.href
    });
  }, [location, pageName]);
}; 