// Analytics utility for GA4 event tracking

// Check if GA is loaded
const isGAAvailable = () => {
  return typeof window !== 'undefined' && window.gtag;
};

// Generic event tracking
export const trackEvent = (eventName, eventParams = {}) => {
  if (isGAAvailable()) {
    window.gtag('event', eventName, eventParams);
  }
};

// User engagement events
export const trackQuestionGeneration = (type, mood, complexity) => {
  trackEvent('generate_question', {
    question_type: type,
    mood: mood,
    complexity: complexity,
  });
};

export const trackQuestionVote = (questionId, voteType) => {
  trackEvent('question_vote', {
    question_id: questionId,
    vote_type: voteType,
  });
};

export const trackQuestionSave = (questionId) => {
  trackEvent('question_save', {
    question_id: questionId,
  });
};

// User authentication events
export const trackSignUp = (method = 'email') => {
  trackEvent('sign_up', {
    method: method,
  });
};

export const trackSignIn = (method = 'email') => {
  trackEvent('login', {
    method: method,
  });
};

// Navigation events
export const trackNavigation = (from, to) => {
  trackEvent('page_navigation', {
    from_page: from,
    to_page: to,
  });
};

// Feature usage events
export const trackFeatureUse = (featureName, actionType) => {
  trackEvent('feature_use', {
    feature_name: featureName,
    action_type: actionType,
  });
};

// Error tracking
export const trackError = (errorType, errorMessage) => {
  trackEvent('error_occurred', {
    error_type: errorType,
    error_message: errorMessage,
  });
};

// User properties
export const setUserProperties = (properties) => {
  if (isGAAvailable()) {
    window.gtag('set', 'user_properties', properties);
  }
};

// Add subscription tracking functions
export const trackSubscriptionAttempt = (plan) => {
  if (window.gtag) {
    window.gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: plan === 'yearly' ? 96 : 10,
      items: [{
        item_name: `${plan} subscription`,
        price: plan === 'yearly' ? 96 : 10
      }]
    });
  }
};

export const trackSubscriptionSuccess = (plan) => {
  if (window.gtag) {
    window.gtag('event', 'purchase', {
      currency: 'USD',
      value: plan === 'yearly' ? 96 : 10,
      items: [{
        item_name: `${plan} subscription`,
        price: plan === 'yearly' ? 96 : 10
      }]
    });
  }
};

// Add this helper function at the top of the file
const calculateAccountAge = (createdAt) => {
  if (!createdAt) return 0;
  const created = new Date(createdAt);
  const now = new Date();
  const diffTime = Math.abs(now - created);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}; 