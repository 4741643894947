import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

const SubscriptionContext = createContext();

export function SubscriptionProvider({ children }) {
  const { user, supabase } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchSubscriptionStatus() {
      if (!user) {
        setSubscriptionStatus(null);
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching subscription status for user:', user.id);
        const { data: profile, error } = await supabase
          .from('profiles')
          .select('subscription_status')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching subscription:', error);
          throw error;
        }

        console.log('Fetched subscription data:', profile);
        setSubscriptionStatus(profile);
      } catch (err) {
        console.error('Error in fetchSubscriptionStatus:', err);
        setSubscriptionStatus(null);
      } finally {
        setLoading(false);
      }
    }

    fetchSubscriptionStatus();
  }, [user, supabase]);

  const value = {
    subscriptionStatus,
    loading
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscriptionStatus() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscriptionStatus must be used within a SubscriptionProvider');
  }
  return context;
} 