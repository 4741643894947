// src/components/checkingenerator.js

import React, { useRef, memo, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../contexts/SubscriptionContext';
import QuestionActions from './QuestionActions';
import useQuestionGenerator from '../hooks/useQuestionGenerator';
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { Helmet } from 'react-helmet-async';

// Memoized QuestionActions component
const MemoizedQuestionActions = memo(QuestionActions);

// Memoized control option
const ControlOption = memo(({ 
  label, 
  emoji, 
  isSelected, 
  onChange 
}) => (
  <label className="flex items-center justify-between text-xl">
    <span>{emoji} {label}</span>
    <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={onChange}
        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
      />
      <div
        className={`w-full h-full rounded-full ${
          isSelected ? 'bg-green-500' : 'bg-gray-200'
        }`}
      />
    </div>
  </label>
));

export default function CheckInGenerator() {
  const { user } = useAuth();
  const { subscriptionStatus } = useSubscriptionStatus();
  const questionBoxRef = useRef(null);
  const [expandedEnergizers, setExpandedEnergizers] = useState(new Set());
  const [error] = useState(null);

  const {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    generatedQuestion,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion,
    fetchPreviousQuestions
  } = useQuestionGenerator();

  // Debug subscription status
  useEffect(() => {
    console.log('Current subscription status:', subscriptionStatus);
  }, [subscriptionStatus]);

  // Add debug logging
  useEffect(() => {
    console.log('Debug Subscription Values:', {
      user: !!user,
      subscriptionStatus: subscriptionStatus?.subscription_status,
      isActive: subscriptionStatus?.isActive,
    });
  }, [user, subscriptionStatus]);

  // Scroll to top nav after generating
  const handleGenerate = async () => {
    console.log('Current state:', {
      isLoading,
      user: !!user,
      generationsLeft,
      selectedType,
      selectedMood,
      selectedComplexity
    });
    
    await generateQuestion();
    console.log('✅ Generation completed');
    
    // Find the header element and scroll to it
    const headerElement = document.querySelector('header');
    if (headerElement) {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
  };

  const toggleEnergizer = (id) => {
    setExpandedEnergizers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  useEffect(() => {
    if (user) {
      fetchPreviousQuestions();
    }
  }, [user, selectedType, fetchPreviousQuestions]);

  return (
    <>
      <Helmet>
        <title>Check-In Questions - AI-Powered Check-In Questions Generator</title>
        <meta name="description" content="Generate engaging check-in questions, energizers, and icebreakers for your team meetings. Use AI to create personalized questions that foster connection and engagement." />
      </Helmet>
      <div ref={questionBoxRef} className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        {/* Question Display Box */}
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
          {error ? (
            <div className="text-red-500 text-center">
              <p className="text-xl font-semibold mb-2">Oops! Something went wrong</p>
              <p>{error}</p>
            </div>
          ) : isLoading ? (
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-400 mx-auto mb-4"></div>
              <p className="text-base text-gray-400">Generating your question...</p>
            </div>
          ) : generatedQuestion ? (
            <div className="bg-gray-100 rounded-lg p-6">
              <div className="mb-4">
                {generatedQuestion.type === 'energizer' ? (
                  <span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                    Energizer
                  </span>
                ) : generatedQuestion.type === 'check-in' ? (
                  <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
                    Check-In Question
                  </span>
                ) : (
                  <span className="inline-block bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
                    Check-Out Question
                  </span>
                )}
              </div>

              {generatedQuestion.type === 'energizer' ? (
                <div className="space-y-4">
                  <div className="space-y-4">
                    <h2 className="text-4xl font-bold">
                      {generatedQuestion.emoji} {generatedQuestion.title}
                    </h2>
                    <p className="text-gray-700">{generatedQuestion.description}</p>
                  </div>
                  <button
                    onClick={() => toggleEnergizer(generatedQuestion.id)}
                    className="flex items-center gap-2 text-blue-600 mt-4 hover:text-blue-700"
                  >
                    <span className="font-medium">
                      {expandedEnergizers.has(generatedQuestion.id) ? 'Show less' : 'Read more'}
                    </span>
                    {expandedEnergizers.has(generatedQuestion.id) ? <CaretUp size={20} /> : <CaretDown size={20} />}
                  </button>
                  {expandedEnergizers.has(generatedQuestion.id) && (
                    <div className="pt-4 space-y-6">
                      <div className="bg-white p-4 rounded-lg">
                        <p className="text-gray-700"><strong>⏱️ Time:</strong> {generatedQuestion.timeFrame}</p>
                        <p className="text-gray-700"><strong>👥 Group Size:</strong> {generatedQuestion.groupSize}</p>
                        <p className="text-gray-700"><strong>🎯 Purpose:</strong> {generatedQuestion.purpose}</p>
                      </div>
                      {generatedQuestion.steps && (
                        <div>
                          <h3 className="font-bold text-lg mb-2">Steps</h3>
                          <ol className="list-decimal ml-6 space-y-2">
                            {generatedQuestion.steps.map((step, index) => (
                              <li key={index}>{step}</li>
                            ))}
                          </ol>
                        </div>
                      )}
                      {generatedQuestion.facilitatorNotes && (
                        <div>
                          <h3 className="font-bold text-lg mb-2">Facilitator Notes</h3>
                          <ul className="list-disc ml-6 space-y-2">
                            {generatedQuestion.facilitatorNotes.map((note, index) => (
                              <li key={index}>{note}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center">
                  <p className="text-4xl font-bold">
                    &ldquo;{(generatedQuestion.content || generatedQuestion).replace(/['"]+/g, '')}&rdquo;
                  </p>
                </div>
              )}
              <QuestionActions
                question={generatedQuestion}
                onVote={fetchPreviousQuestions}
              />
            </div>
          ) : (
            <div className="text-center text-gray-500">
              <p className="text-xl">Click Generate to create a new question</p>
            </div>
          )}
        </div>

        {/* Controls Box */}
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {/* Type Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Type</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Check-In"
                  emoji="👋"
                  isSelected={selectedType === 'check-in'}
                  onChange={() => handleTypeChange('check-in')}
                />
                <ControlOption
                  label="Energizer"
                  emoji="⚡️"
                  isSelected={selectedType === 'energizer'}
                  onChange={() => handleTypeChange('energizer')}
                />
                <ControlOption
                  label="Check-Out"
                  emoji="🏁"
                  isSelected={selectedType === 'check-out'}
                  onChange={() => handleTypeChange('check-out')}
                />
              </div>
            </div>

            {/* Mood Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Mood</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Curious"
                  emoji="🤔"
                  isSelected={selectedMood === 'curious'}
                  onChange={() => handleMoodChange('curious')}
                />
                <ControlOption
                  label="Personal"
                  emoji="👤"
                  isSelected={selectedMood === 'personal'}
                  onChange={() => handleMoodChange('personal')}
                />
                <ControlOption
                  label="Weird"
                  emoji="🌀"
                  isSelected={selectedMood === 'weird'}
                  onChange={() => handleMoodChange('weird')}
                />
              </div>
            </div>

            {/* Complexity Column */}
            <div>
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Complexity</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Simple"
                  emoji="✅"
                  isSelected={selectedComplexity === 'simple'}
                  onChange={() => handleComplexityChange('simple')}
                />
                <ControlOption
                  label="Moderate"
                  emoji="🔸"
                  isSelected={selectedComplexity === 'moderate'}
                  onChange={() => handleComplexityChange('moderate')}
                />
                <ControlOption
                  label="Deep"
                  emoji="🧠"
                  isSelected={selectedComplexity === 'deep'}
                  onChange={() => handleComplexityChange('deep')}
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <button
              onClick={handleGenerate}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-xl text-xl transition duration-200 ease-in-out transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:bg-gray-400"
              disabled={isLoading || (!user && generationsLeft === 0)}
            >
              {isLoading 
                ? 'Generating...' 
                : !user && generationsLeft === 0
                ? 'Free limit reached'
                : 'Generate'}
            </button>
            {!user && (
              <div className="text-center mt-4">
                <p className="text-gray-600 mb-2">
                  {generationsLeft > 0 
                    ? `${generationsLeft} free generations remaining`
                    : "You've reached the limit for free generations"}
                </p>
                <a 
                  href="/sign-up" 
                  className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                >
                  Create account <span className="ml-1">→</span>
                </a>
              </div>
            )}
          </div>
        </div>

        {/* Premium Upgrade Box - Only show for non-premium users */}
        {user && subscriptionStatus?.subscription_status !== 'premium' && (
          <div className="mt-6 mb-6 bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl text-white p-8">
            <div className="flex flex-col md:flex-row items-start gap-8">
              <div className="flex-1 space-y-6">
                <div>
                  <h2 className="text-2xl font-bold mb-2">Check-in Questions Premium</h2>
                  <p className="opacity-90">Your all-in-one solution for creating engaging team moments and fostering psychological safety.</p>
                </div>

                <ul className="space-y-3">
                  <li className="flex items-center">
                    <span className="mr-3">✨</span>
                    Unlimited access to our AI-powered question generator
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🎯</span>
                    Advanced energizer activities and team exercises
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">📈</span>
                    Access to top-rated questions from our global community
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Monthly online workshops on facilitation best practices
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Join our global community of facilitators and team leaders
                  </li>
                </ul>

                <div className="bg-white/10 rounded-xl p-6 space-y-3">
                  <p className="font-semibold">Perfect for team leaders, facilitators, and managers who want to:</p>
                  <ul className="space-y-2 opacity-90">
                    <li>• Create more meaningful team connections</li>
                    <li>• Learn new facilitation techniques</li>
                    <li>• Share experiences with peers globally</li>
                    <li>• Stay updated with latest team engagement practices</li>
                  </ul>
                </div>
              </div>

              <div className="w-full md:w-1/4 flex flex-col items-center md:items-end gap-6">
                <div className="text-center md:text-right">
                  <p className="text-lg opacity-90 mb-1">Starting at</p>
                  <p className="text-4xl font-bold">$5/month</p>
                </div>
                <a
                  href="/pricing"
                  className="inline-block bg-white text-blue-600 font-bold py-3 px-8 rounded-xl text-lg transition duration-200 ease-in-out transform hover:scale-[1.02] hover:bg-gray-50 w-full md:w-auto text-center"
                >
                  Upgrade to Premium
                </a>
                <p className="text-sm opacity-75 text-center md:text-right">
                  Includes access to monthly virtual workshops with experienced facilitators
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Popular Questions - Only show for premium users */}
        {user && subscriptionStatus?.subscription_status === 'premium' && previousQuestions.length > 0 && (
          <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-6">
            <h2 className="text-2xl font-bold mb-6">
              Most Popular {selectedType === 'check-in' 
                ? 'Check-In Questions' 
                : selectedType === 'energizer' 
                  ? 'Team Energizers' 
                  : 'Check-Out Questions'}
            </h2>
            <div className="space-y-6">
              {previousQuestions
                .filter(question => {
                  const votes = parseInt(question.voteCount) || 0;
                  return votes >= 1;
                })
                .slice(0, 20)
                .map((question) => (
                  <div
                    key={question.id}
                    className="bg-gray-100 rounded-lg p-6"
                  >
                    <div className="mb-4">
                      {question.type === 'energizer' ? (
                        <span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                          Energizer
                        </span>
                      ) : question.type === 'check-in' ? (
                        <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
                          Check-In Question
                        </span>
                      ) : (
                        <span className="inline-block bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
                          Check-Out Question
                        </span>
                      )}
                    </div>

                    {question.type === 'energizer' ? (
                      <div>
                        {(() => {
                          try {
                            const energizer = typeof question.content === 'string' 
                              ? JSON.parse(question.content) 
                              : question.content;

                            return (
                              <div className="space-y-4">
                                <div className="space-y-4">
                                  <h2 className="text-2xl font-bold">
                                    {energizer.emoji} {energizer.title}
                                  </h2>
                                  <p className="text-gray-700">{energizer.description}</p>
                                </div>

                                <button
                                  onClick={() => toggleEnergizer(question.id)}
                                  className="flex items-center gap-2 text-blue-600 mt-4 hover:text-blue-700"
                                >
                                  <span className="font-medium">
                                    {expandedEnergizers.has(question.id) ? 'Show less' : 'Read more'}
                                  </span>
                                  {expandedEnergizers.has(question.id) ? <CaretUp size={20} /> : <CaretDown size={20} />}
                                </button>

                                {expandedEnergizers.has(question.id) && (
                                  <div className="pt-4 space-y-6">
                                    <div className="bg-white p-4 rounded-lg">
                                      <p className="text-gray-700"><strong>⏱️ Time:</strong> {energizer.timeFrame}</p>
                                      <p className="text-gray-700"><strong>👥 Group Size:</strong> {energizer.groupSize}</p>
                                      <p className="text-gray-700"><strong>🎯 Purpose:</strong> {energizer.purpose}</p>
                                    </div>
                                    {energizer.steps && (
                                      <div>
                                        <h3 className="font-bold text-lg mb-2">Steps</h3>
                                        <ol className="list-decimal ml-6 space-y-2">
                                          {energizer.steps.map((step, index) => (
                                            <li key={index}>{step}</li>
                                          ))}
                                        </ol>
                                      </div>
                                    )}
                                    {energizer.facilitatorNotes && (
                                      <div>
                                        <h3 className="font-bold text-lg mb-2">Facilitator Notes</h3>
                                        <ul className="list-disc ml-6 space-y-2">
                                          {energizer.facilitatorNotes.map((note, index) => (
                                            <li key={index}>{note}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          } catch (error) {
                            console.error('Error parsing energizer content:', error);
                            return <p className="text-red-500">Error displaying energizer content</p>;
                          }
                        })()}
                      </div>
                    ) : (
                      <div>
                        <p className="text-2xl font-bold text-center mb-4">&ldquo;{(question.content || question).replace(/['"]+/g, '')}&rdquo;</p>
                      </div>
                    )}
                    <MemoizedQuestionActions
                      question={question}
                      onVote={fetchPreviousQuestions}
                    />
                    <div className="text-center mt-4 text-sm text-gray-500">
                      {question.voteCount || 0} helpful {(question.voteCount === 1) ? 'vote' : 'votes'}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        {!user && (
          <div className="mt-6 mb-6 bg-white rounded-3xl shadow-lg p-8 md:p-12">
            <h1 className="text-3xl font-bold mb-6">Transform Your Team Meetings with AI-Powered Check-In Questions</h1>
            
            <p className="mb-8 text-gray-700">
              Welcome to Check-In Questions, your intelligent AI companion for creating meaningful team connections and fostering psychological safety in virtual, hybrid, and in-person meetings. Our advanced platform harnesses the power of artificial intelligence and OpenAI's technology, combined with proven team building and facilitation techniques, to generate thoughtful, engaging check-in questions and team icebreakers. Whether you're running daily stand-ups, sprint retrospectives, team building workshops, or leadership meetings, our smart question generator helps meeting facilitators and team leaders spark authentic conversations that strengthen team dynamics and improve employee engagement.</p>

            <div className="grid md:grid-cols-2 gap-8 mb-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">✨ Why Teams Choose Us</h2>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="mr-3">🤝</span>
                    Build psychological safety and trust
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🎯</span>
                    Increase meeting engagement
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🌈</span>
                    Create inclusive team environments
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🚀</span>
                    Accelerate team development
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🌐</span>
                    Perfect for remote & hybrid teams
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">💫 Perfect For</h2>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="mr-3">👥</span>
                    Team Leaders & Managers
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">⚡️</span>
                    Agile Coaches & Scrum Masters
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">📚</span>
                    Educators & Facilitators
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">💡</span>
                    Innovation Teams
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🤗</span>
                    Community Leaders
                  </li>
                </ul>
              </div>
            </div>

            <p className="mb-8 text-gray-700">
              As remote work and distributed teams become increasingly common, building trust and maintaining team cohesion has never been more crucial. Our AI-powered meeting tools help Scrum Masters, Agile Coaches, HR professionals, and team managers create inclusive environments where every team member feels heard and valued. By incorporating expert-designed check-in questions and team energizers into your meeting rituals, you'll enhance team collaboration, boost meeting participation, and develop the psychological safety that's essential for high-performing teams.
            </p>

            <h2 className="text-2xl font-semibold mb-4">🎯 A Comprehensive Platform</h2>
            <p className="text-gray-700 mb-6">
              Our platform offers three powerful interaction types: 👋 Check-In Questions to start meetings meaningfully, ⚡️ Energizers to boost engagement, and 🏁 Check-Out Questions to close sessions with purpose. Each question adapts to your preferred mood and complexity, ensuring perfect alignment with your team's needs.
            </p>

            <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl text-white p-8 mb-6">
              <h3 className="text-xl font-semibold mb-4">🌟 Premium Features</h3>
              <div className="grid md:grid-cols-2 gap-6 mb-2">
                <div>
                  <ul className="space-y-3 text-white">
                    <li className="flex items-start">
                      <span className="mr-3">✨</span>
                      Unlimited AI-powered questions
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">🎯</span>
                      Advanced team energizers
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">📈</span>
                      Access curated question library
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="space-y-3 text-white">
                    <li className="flex items-start">
                      <span className="mr-3">🎓</span>
                      Monthly facilitation workshops
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">👥</span>
                      Global facilitator community
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">💫</span>
                      Priority feature access
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p className="text-gray-700 mb-6">
              Start your journey today with 25 free questions daily. Experience how our AI-powered platform can transform your team meetings into engaging, meaningful conversations that build stronger connections and drive better outcomes. Join thousands of facilitators worldwide who are already using Check-In Questions to create more impactful team moments.
            </p>

            <div className="flex justify-center">
              <a
                href="/sign-up"
                className="inline-block bg-green-500 text-white font-medium py-3 px-8 rounded-xl text-lg text-center transition duration-200 ease-in-out transform hover:scale-[1.04] hover:bg-green-600"
              >
                Start Your Journey with a Free Account Today ✨
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}