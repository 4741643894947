import React, { useState, useEffect, useCallback } from 'react';
import { ThumbsUp, ThumbsDown, Star } from '@phosphor-icons/react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function QuestionActions({ question, onVote }) {
  const { supabase, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [savedStatus, setSavedStatus] = useState(false);
  const [helpfulStatus, setHelpfulStatus] = useState(null);

  const checkVoteStatus = useCallback(async () => {
    if (!user?.id || !question?.id) return;
    
    try {
      const { data, error } = await supabase
        .from('votes_questions')
        .select('vote_type')
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error checking vote status:', error);
        return;
      }

      if (data && data.length > 0) {
        setHelpfulStatus(data[0].vote_type);
      } else {
        setHelpfulStatus(null);
      }
    } catch (error) {
      console.error('Error checking vote status:', error);
    }
  }, [user, question, supabase]);

  const checkSavedStatus = useCallback(async () => {
    if (!user?.id || !question?.id) return;
    
    try {
      const { data, error } = await supabase
        .from('saved_questions')
        .select('id')
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error checking saved status:', error);
        return;
      }

      setSavedStatus(data && data.length > 0);
    } catch (error) {
      console.error('Error checking saved status:', error);
    }
  }, [user, question, supabase]);

  useEffect(() => {
    if (user && question) {
      checkSavedStatus();
      checkVoteStatus();
    }
  }, [user, question, checkSavedStatus, checkVoteStatus]);

  const handleVote = async (voteType) => {
    if (!user || !question?.id) return;
    
    setIsLoading(true);
    try {
      // Delete existing vote if any
      await supabase
        .from('votes_questions')
        .delete()
        .eq('question_id', question.id)
        .eq('user_id', user.id);

      // Insert new vote if not removing
      if (voteType !== helpfulStatus) {
        const { error: voteError } = await supabase
          .from('votes_questions')
          .insert([{
            question_id: question.id,
            user_id: user.id,
            vote_type: voteType
          }]);

        if (voteError) throw voteError;
      }

      // Update local state
      setHelpfulStatus(voteType === helpfulStatus ? null : voteType);
      
      // Trigger refresh of parent component
      if (onVote) onVote();
      
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!user) return;
    setIsLoading(true);
    
    try {
      if (!savedStatus) {
        // Save question
        if (question.id) {
          const { error } = await supabase
            .from('saved_questions')
            .insert({
              question_id: question.id,
              user_id: user.id,
              created_at: new Date().toISOString()
            });

          if (error) throw error;
        }
        setSavedStatus(true);
      } else {
        // Unsave question
        if (question.id) {
          const { error } = await supabase
            .from('saved_questions')
            .delete()
            .eq('question_id', question.id)
            .eq('user_id', user.id);

          if (error) throw error;
        }
        setSavedStatus(false);
      }
    } catch (error) {
      console.error('Error saving/unsaving question:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = isLoading || !user || !question;

  return (
    <div className="flex flex-col md:flex-row gap-2 w-full pt-4">
      <div className="group relative flex-1">
        <button
          type="button"
          onClick={() => handleVote('helpful')}
          disabled={isDisabled}
          className={`w-full px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
            helpfulStatus === 'helpful' 
              ? 'bg-green-500 text-white hover:bg-green-600' 
              : 'bg-gray-200 hover:bg-gray-300'
          } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ThumbsUp weight={helpfulStatus === 'helpful' ? 'fill' : 'regular'} />
          {helpfulStatus === 'helpful' ? 'Helpful!' : 'Helpful'}
        </button>
        {!user && (
          <div className="invisible group-hover:visible absolute -top-12 left-1/2 -translate-x-1/2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg whitespace-nowrap">
            <Link to="/sign-up">Sign up to vote on questions</Link>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-4 border-transparent border-t-gray-900"></div>
          </div>
        )}
      </div>
      
      <div className="group relative flex-1">
        <button
          type="button"
          onClick={() => handleVote('not_helpful')}
          disabled={isDisabled}
          className={`w-full px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
            helpfulStatus === 'not_helpful' 
              ? 'bg-red-500 text-white hover:bg-red-600' 
              : 'bg-gray-200 hover:bg-gray-300'
          } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ThumbsDown weight={helpfulStatus === 'not_helpful' ? 'fill' : 'regular'} />
          {helpfulStatus === 'not_helpful' ? 'Not Helpful!' : 'Not Helpful'}
        </button>
        {!user && (
          <div className="invisible group-hover:visible absolute -top-12 left-1/2 -translate-x-1/2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg whitespace-nowrap">
            <Link to="/sign-up">Sign up to vote on questions</Link>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-4 border-transparent border-t-gray-900"></div>
          </div>
        )}
      </div>

      <div className="group relative flex-1">
        <button
          type="button"
          onClick={handleSave}
          disabled={isDisabled}
          className={`w-full px-4 py-2 flex items-center justify-center gap-2 rounded-lg transition-colors ${
            savedStatus 
              ? 'bg-yellow-500 text-white hover:bg-yellow-600' 
              : 'bg-gray-200 hover:bg-gray-300'
          } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <Star weight={savedStatus ? 'fill' : 'regular'} />
          {savedStatus ? 'Saved!' : 'Save'}
        </button>
        {!user && (
          <div className="invisible group-hover:visible absolute -top-12 left-1/2 -translate-x-1/2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg whitespace-nowrap">
            <Link to="/sign-up">Sign up to save questions</Link>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-4 border-transparent border-t-gray-900"></div>
          </div>
        )}
      </div>
    </div>
  );
}