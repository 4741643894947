import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function AuthCallback() {
  const navigate = useNavigate();
  const { supabase } = useAuth();
  const location = useLocation();

  useEffect(() => {
    console.log('Auth callback mounted', {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash
    });

    const handleCallback = async () => {
      try {
        console.log('Handling auth callback...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        console.log('Auth result:', { session: !!session, error });
        
        if (error) throw error;
        
        if (session) {
          const params = new URLSearchParams(window.location.search);
          const next = params.get('next') || '/profile';
          console.log('Redirecting to:', next);
          navigate(next, { replace: true });
        } else {
          console.log('No session, redirecting to sign-in');
          navigate('/sign-in', { replace: true });
        }
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/sign-in', { replace: true });
      }
    };

    handleCallback();
  }, [navigate, supabase.auth, location]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-pulse text-lg">Completing sign in...</div>
    </div>
  );
} 