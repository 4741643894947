import React from 'react';
import { useAuth } from '../contexts/AuthContext';

export default function GoogleSignInButton({ onError }) {
  const { signInWithGoogle } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error('Google sign in error:', err);
      onError(err.message);
    }
  };

  return (
    <button
      type="button"
      onClick={handleGoogleSignIn}
      className="w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.766 12.2764C23.766 11.4607 23.6999 10.6406 23.5588 9.83807H12.24V14.4591H18.7217C18.4528 15.9494 17.5885 17.2678 16.323 18.1056V21.1039H20.19C22.4608 19.0139 23.766 15.9274 23.766 12.2764Z" fill="#4285F4"/>
        <path d="M12.24 24.0008C15.4764 24.0008 18.2058 22.9382 20.1944 21.1039L16.3274 18.1055C15.2516 18.8375 13.8626 19.252 12.24 19.252C9.0362 19.252 6.3106 17.1399 5.3646 14.3003H1.3916V17.3912C3.37038 21.4434 7.48074 24.0008 12.24 24.0008Z" fill="#34A853"/>
        <path d="M5.36451 14.3003C4.85051 12.8099 4.85051 11.1961 5.36451 9.70575V6.61481H1.39157C-0.466534 10.0056 -0.466534 14.0004 1.39157 17.3912L5.36451 14.3003Z" fill="#FBBC04"/>
        <path d="M12.24 4.74966C13.9508 4.7232 15.6043 5.36697 16.8433 6.54867L20.2694 3.12262C18.1 1.0855 15.2207 -0.034466 12.24 0.000808666C7.48074 0.000808666 3.37038 2.55822 1.3916 6.61481L5.36454 9.70575C6.30654 6.86616 9.0321 4.74966 12.24 4.74966Z" fill="#EA4335"/>
      </svg>
      Continue with Google
    </button>
  );
} 