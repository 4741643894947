import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import QuestionActions from './QuestionActions';

export default function SavedQuestions() {
  const { user, supabase } = useAuth();
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedQuestions, setExpandedQuestions] = useState(new Set());

  const fetchSavedQuestions = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('saved_questions')
        .select(`
          id,
          created_at,
          generated_questions:question_id (
            id,
            question,
            type
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching saved questions:', error);
        return;
      }

      setSavedQuestions(data || []);
    } catch (error) {
      console.error('Error fetching saved questions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedQuestions();
  }, [user, supabase]);

  const toggleQuestion = (id) => {
    setExpandedQuestions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const renderEnergizerContent = (questionText, isExpanded) => {
    try {
      const parsedContent = JSON.parse(questionText);

      return (
        <div className="space-y-4">
          <h3 className="text-2xl font-bold">
            {parsedContent.emoji} {parsedContent.title}
          </h3>
          <p className="text-gray-700">{parsedContent.description}</p>
          {isExpanded && (
            <>
              <div className="bg-white p-4 rounded-lg">
                <p><strong>Time:</strong> {parsedContent.timeFrame}</p>
                <p><strong>Group Size:</strong> {parsedContent.groupSize}</p>
                <p><strong>Purpose:</strong> {parsedContent.purpose}</p>
              </div>
              <div>
                <h3 className="font-bold text-lg mb-2">Steps</h3>
                <ol className="list-decimal ml-6 space-y-2">
                  {parsedContent.steps.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ol>
              </div>
              {parsedContent.facilitatorNotes && (
                <div>
                  <h3 className="font-bold text-lg mb-2">Facilitator Notes</h3>
                  <ul className="list-disc ml-6 space-y-2">
                    {parsedContent.facilitatorNotes.map((note, index) => (
                      <li key={index}>{note}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      );
    } catch (error) {
      console.error('Error parsing energizer content:', error);
      return <p className="text-red-500">Error displaying energizer content</p>;
    }
  };

  const getQuestionTypePill = (type) => {
    switch (type) {
      case 'energizer':
        return (
          <span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
            Energizer
          </span>
        );
      case 'check-in':
        return (
          <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
            Check-In Question
          </span>
        );
      case 'check-out':
        return (
          <span className="inline-block bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
            Check-Out Question
          </span>
        );
      default:
        return null;
    }
  };

  const renderQuestion = (savedQuestion) => {
    const question = savedQuestion?.generated_questions;
    if (!question) return null;
    const isExpanded = expandedQuestions.has(savedQuestion.id);

    return (
      <div key={savedQuestion.id} className="bg-gray-100 rounded-lg p-6">
        <div className="space-y-4">
          <div className="mb-2">
            {getQuestionTypePill(question.type)}
          </div>
          
          {question.type === 'energizer' ? (
            <div>
              {renderEnergizerContent(question.question, isExpanded)}
              <button
                onClick={() => toggleQuestion(savedQuestion.id)}
                className="flex items-center gap-2 text-blue-600 mt-4 hover:text-blue-700"
              >
                {isExpanded ? 'Show less' : 'Show more'}
                {isExpanded ? <CaretUp size={20} /> : <CaretDown size={20} />}
              </button>
            </div>
          ) : (
            <h2 className="text-2xl font-bold text-center">
              &quot;{question.question}&quot;
            </h2>
          )}
          
          {/* Add helpful votes count */}
          <div className="text-sm text-gray-500 mt-2">
            {/* Temporarily remove votes count until we fix the relationship */}
          </div>
        </div>
        <QuestionActions 
          question={question} 
          onVote={fetchSavedQuestions}
        />
      </div>
    );
  };

  if (loading) {
    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
      <h1 className="text-3xl font-bold mb-6">Saved Questions</h1>
          <p className="text-gray-500 text-center py-8">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
    <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
<h1 className="text-3xl font-bold mb-6">
  Saved Questions ({savedQuestions.length})
</h1>
        {savedQuestions.length === 0 ? (
          <p className="text-gray-500 text-center py-8">No saved questions yet.</p>
        ) : (
          <div className="space-y-6">
            {savedQuestions.map(renderQuestion)}
          </div>
        )}
      </div>
    </div>
  );
} 