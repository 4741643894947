// Stripe configuration
const STRIPE_MODE = process.env.REACT_APP_STRIPE_MODE || 'test';

export const SUBSCRIPTION_PRICES = {
    test: {
        monthly: process.env.REACT_APP_STRIPE_TEST_PRICE_MONTHLY,
        yearly: process.env.REACT_APP_STRIPE_TEST_PRICE_YEARLY
    },
    live: {
        monthly: process.env.REACT_APP_STRIPE_LIVE_PRICE_MONTHLY,
        yearly: process.env.REACT_APP_STRIPE_LIVE_PRICE_YEARLY
    }
};

export const getStripePublishableKey = () => {
    return STRIPE_MODE === 'live'
        ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
};

export const DOMAIN = process.env.REACT_APP_URL || window.location.origin;