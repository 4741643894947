// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import About from './components/About';
import Contact from './components/Contact';
import Support from './components/Support';
import CheckInGenerator from './components/CheckInGenerator';
import Profile from './components/Profile';
import PaymentSuccess from './components/PaymentSuccess';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import HowTo from './components/HowTo';
import CookieConsent from './components/CookieConsent';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import AuthCallback from './components/AuthCallback';
import VerifyEmail from './pages/VerifyEmail';
import SavedQuestions from './components/SavedQuestions';
import { usePageTracking } from './hooks/usePageTracking';

export default function App() {
  usePageTracking();
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-500 via-blue-400 via-70% to-blue-200 flex flex-col">
      <div className="flex-grow">
        <Navigation />
        <Routes>
          {/* Public routes */}
          <Route path="/auth/v1/callback" element={<AuthCallback />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/how-to" element={<HowTo />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />

          {/* Root route - accessible to both authenticated and unauthenticated users */}
          <Route path="/" element={<CheckInGenerator />} />

          {/* Protected routes */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/saved-questions"
            element={
              <ProtectedRoute>
                <SavedQuestions />
              </ProtectedRoute>
            }
          />
        </Routes>
        <CookieConsent />
      </div>
      <Footer />
    </div>
  );
}