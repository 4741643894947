import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import type { AuthContextType } from '../contexts/types';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAuth?: boolean;
}

export default function ProtectedRoute({ children, requireAuth = true }: ProtectedRouteProps) {
  const { user } = useAuth() as AuthContextType;

  // If authentication is required and user is not logged in, redirect to sign-in
  if (requireAuth && !user) {
    return <Navigate to="/sign-in" replace />;
  }

  // If user is logged in and tries to access auth pages, redirect to home
  if (user && !requireAuth) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the protected component
  return <>{children}</>;
} 