import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { usePageTracking } from '../hooks/usePageTracking';

export default function Contact() {
    usePageTracking('Contact Page');
    const [formData, setFormData] = useState({
        from_name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState({ type: '', message: '' });
    const formRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ type: 'info', message: 'Sending message...' });

        try {
            console.log('Attempting to send email...');
            const result = await emailjs.sendForm(
                'service_4t1h3z4',
                'template_f3vohr6',
                formRef.current,
                'tACSpjS6SmkFAVooe'
            );

            console.log('EmailJS Response:', result);

            if (result.text === 'OK') {
                setStatus({
                    type: 'success',
                    message: 'Thank you! Your message has been sent successfully.'
                });
                setFormData({
                    from_name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            }
        } catch (error) {
            console.error('EmailJS Error:', {
                message: error.message,
                text: error.text,
                details: error
            });
            setStatus({
                type: 'error',
                message: 'Sorry, there was an error sending your message. Please try again or email us directly.'
            });
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
                <h1 className="text-3xl font-bold mb-6">Contact Us</h1>

                <p className="mb-8 text-gray-700">
                    Have questions, suggestions, or feedback? We'd love to hear from you! You can reach us directly at{' '}
                    <a 
                        href="mailto:hello@checkinquestions.com" 
                        className="text-blue-600 hover:text-blue-800 underline"
                    >
                        hello@checkinquestions.com
                    </a>{' '}
                    or use the contact form below.
                </p>

                {status.message && (
                    <div 
                        className={`mb-6 p-4 rounded-md ${
                            status.type === 'success' 
                                ? 'bg-green-50 text-green-800' 
                                : status.type === 'error'
                                ? 'bg-red-50 text-red-800'
                                : 'bg-blue-50 text-blue-800'
                        }`}
                    >
                        {status.message}
                    </div>
                )}

                <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="from_name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                type="text"
                                name="from_name"
                                id="from_name"
                                value={formData.from_name}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-100"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-100"
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                            Subject
                        </label>
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-100"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                            Message
                        </label>
                        <textarea
                            name="message"
                            id="message"
                            rows={6}
                            value={formData.message}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-100"
                            required
                        />
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="w-full md:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                            disabled={status.type === 'info'}
                        >
                            {status.type === 'info' ? 'Sending...' : 'Send Message'}
                        </button>
                    </div>
                </form>

                <div className="mt-8 text-sm text-gray-500">
                    <p>
                        We typically respond within 1-2 business days. For urgent matters, please email us directly.
                    </p>
                </div>
            </div>
        </div>
    );
}
