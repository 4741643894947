import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { trackEvent } from '../lib/analytics';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const [status, setStatus] = useState('pending');
  const [error, setError] = useState('');
  const [resendCooldown, setResendCooldown] = useState(0);
  const navigate = useNavigate();
  const { verifyEmail, resendVerificationEmail } = useAuth();

  const handleVerification = useCallback(async () => {
    try {
      await verifyEmail(token);
      setStatus('success');
      trackEvent('email_verification_success');
      // Redirect to home after 2 seconds
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      console.error('Verification error:', err);
      setStatus('error');
      setError(err.message || 'Verification failed');
      trackEvent('email_verification_error', { error: err.message });
    }
  }, [token, verifyEmail, navigate]);

  useEffect(() => {
    if (token) {
      handleVerification();
    }
  }, [token, handleVerification]);

  // Countdown timer for resend cooldown
  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setTimeout(() => {
        setResendCooldown(prev => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendCooldown]);

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail(email);
      setResendCooldown(60); // Set 60 second cooldown
      trackEvent('verification_email_resent');
    } catch (err) {
      console.error('Resend verification error:', err);
      let errorMessage = 'Failed to resend verification email. Please try again later.';
      
      if (err.message?.toLowerCase().includes('rate limit')) {
        errorMessage = 'You have reached the maximum number of verification email requests (3 per hour). Please wait and try again later.';
      }
      
      setError(errorMessage);
      trackEvent('verification_email_resend_error', { error: err.message });
    }
  };

  if (token) {
    return (
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 text-center">
          {status === 'pending' && (
            <>
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black mx-auto mb-4"></div>
              <h2 className="text-2xl font-bold mb-2">Verifying your email...</h2>
              <p className="text-gray-600">Please wait while we confirm your email address.</p>
            </>
          )}
          
          {status === 'success' && (
            <>
              <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2">Email Verified!</h2>
              <p className="text-gray-600">Redirecting you to the home page...</p>
            </>
          )}
          
          {status === 'error' && (
            <>
              <div className="mx-auto w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2">Verification Failed</h2>
              <p className="text-red-600 mb-4">{error}</p>
              <Link to="/" className="text-blue-600 hover:text-blue-800 font-medium">
                Return to Home
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }

  // Instructions view (no token)
  return (
    <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
      <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">
        
        <h1 className="text-3xl font-bold mb-6">Check Your Email</h1>
        <div className="space-y-4 text-gray-600">
          <p className="text-lg">
            We've sent you a verification link to complete your registration.

            Click the link in the email to verify your account and start using Check-In Questions.
          </p>
          
          <div className="bg-gray-100 p-4 rounded-lg mt-6 text-center">
            <h3 className="font-medium text-gray-900 mb-2">Didn't receive the email?</h3>
            <ul className="text-sm space-y-2">
              <li>• Check your spam folder</li>
              <li>• Make sure you entered the correct email address</li>
              <li>• The email might take up to 10 minutes to arrive</li>
            </ul>
            <div className="mt-4">
              <button
                onClick={handleResendVerification}
                disabled={resendCooldown > 0}
                className="text-blue-600 hover:text-blue-800 font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {resendCooldown > 0 
                  ? `Resend available in ${resendCooldown}s` 
                  : 'Resend verification email'}
              </button>
            </div>
          </div>
          
          <div className="mt-8 text-center">
            <Link to="/" className="text-blue-600 hover:text-blue-800 font-medium">
              Return to Home
            </Link>
          </div>
        </div>

        {error && (
          <p className="mt-4 text-sm text-red-500">{error}</p>
        )}
      </div>
    </div>
  );
} 