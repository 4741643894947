import axios from 'axios';
import md5 from 'md5';

const mailchimpApi = axios.create({
  baseURL: process.env.REACT_APP_MAILCHIMP_API_URL,
  auth: {
    username: 'anystring',
    password: process.env.REACT_APP_MAILCHIMP_API_KEY
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

// Helper to get subscriber hash
const getSubscriberHash = (email) => md5(email.toLowerCase());

export const syncUserWithMailchimp = async (user, subscriptionStatus) => {
  try {
    const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
    if (!listId) {
      console.error('Mailchimp List ID not configured');
      return false;
    }

    const subscriberHash = getSubscriberHash(user.email);

    console.log('Attempting to sync user with Mailchimp:', {
      email: user.email,
      status: subscriptionStatus,
      listId: listId
    });

    const payload = {
      email_address: user.email,
      status: 'subscribed',
      merge_fields: {
        FNAME: user.full_name?.split(' ')[0] || '',
        LNAME: user.full_name?.split(' ').slice(1).join(' ') || '',
        SUBSTATUS: subscriptionStatus,
        SIGNUPDATE: new Date().toISOString().split('T')[0]
      },
      tags: [subscriptionStatus === 'premium' ? 'Premium User' : 'Free User']
    };

    try {
      // Try to update existing member
      const updateResponse = await mailchimpApi.patch(`/lists/${listId}/members/${subscriberHash}`, payload);
      console.log('Successfully updated existing member:', updateResponse.status);
    } catch (error) {
      if (error.response?.status === 404) {
        // Member doesn't exist, create new
        console.log('Member not found, creating new member...');
        const createResponse = await mailchimpApi.post(`/lists/${listId}/members`, payload);
        console.log('Successfully created new member:', createResponse.status);
      } else {
        console.error('Mailchimp API error:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
        throw error;
      }
    }

    console.log('Mailchimp sync successful');
    return true;
  } catch (error) {
    console.error('Error syncing contact to Mailchimp:', {
      error: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    return false;
  }
};

export const updateUserTags = async (email, tags, status = 'active') => {
  try {
    const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
    const subscriberHash = getSubscriberHash(email);

    const payload = {
      tags: tags.map(tag => ({
        name: tag,
        status: status
      }))
    };

    await mailchimpApi.post(`/lists/${listId}/members/${subscriberHash}/tags`, payload);
    return true;
  } catch (error) {
    console.error('Error updating Mailchimp tags:', error);
    return false;
  }
};

export const addToJourneyOrWorkflow = async (email, workflowId) => {
  try {
    const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
    const subscriberHash = getSubscriberHash(email);

    // Add subscriber to a specific automation workflow
    await mailchimpApi.post(`/lists/${listId}/members/${subscriberHash}/actions/start-automation`, {
      workflow_id: workflowId
    });
    
    return true;
  } catch (error) {
    console.error('Error adding to Mailchimp workflow:', error);
    return false;
  }
}; 