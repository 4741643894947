import { loadStripe } from '@stripe/stripe-js';
import { getStripePublishableKey, DOMAIN, SUBSCRIPTION_PRICES } from './stripe-config';

// Initialize Stripe once
let stripePromise;
export const getStripe = () => {
    if (!stripePromise) {
        const key = getStripePublishableKey();
        if (!key) {
            throw new Error('Stripe publishable key is not configured');
        }
        console.log('Initializing Stripe with key:', key.substring(0, 8) + '...');
        stripePromise = loadStripe(key);
    }
    return stripePromise;
};

export { SUBSCRIPTION_PRICES };

export const createCheckoutSession = async (priceId, email) => {
    try {
        const stripe = await getStripe();
        if (!stripe) {
            throw new Error('Failed to initialize Stripe');
        }

        console.log('Creating checkout session for:', {
            priceId,
            email: email ? '***@***.***' : undefined // Mask email in logs
        });

        // Create the checkout session directly with minimal required parameters
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: priceId, quantity: 1 }],
            mode: 'subscription',
            successUrl: `${DOMAIN}/payment/success?session_id={CHECKOUT_SESSION_ID}`,
            cancelUrl: `${DOMAIN}/pricing`,
            customerEmail: email
        });

        if (error) {
            console.error('Stripe checkout error:', error);
            throw error;
        }
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};