import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession } from '../lib/stripe';
import { SUBSCRIPTION_PRICES } from '../lib/stripe-config';
import { trackSubscriptionAttempt } from '../lib/analytics';
import { usePageTracking } from '../hooks/usePageTracking';

export default function Pricing() {
    usePageTracking('Pricing Page');
    const [isAnnual, setIsAnnual] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleSubscribe = async (priceId) => {
        if (!user) {
            navigate('/sign-in', { state: { from: '/pricing' } });
            return;
        }

        setError(null);
        setLoading(true);
        try {
            trackSubscriptionAttempt(isAnnual ? 'yearly' : 'monthly');
            await createCheckoutSession(priceId, user.email);
        } catch (error) {
            console.error('Subscription error:', error);
            setError(error.message || 'Failed to start checkout process. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const mode = process.env.REACT_APP_STRIPE_MODE || 'live';
    const prices = SUBSCRIPTION_PRICES[mode];

    const faqs = [
        {
            question: "What's included in the free plan?",
            answer: "The free plan includes 25 question generations per day for registered users (5 for non-registered users), access to basic question types, and the ability to save your favorite questions."
        },
        {
            question: "Can I switch between monthly and annual billing?",
            answer: "Yes, you can switch between billing periods at any time. If you switch to annual billing, you'll save more than 15% compared to monthly billing."
        },
        {
            question: "How do the monthly workshops work?",
            answer: "Premium members get access to live online workshops led by experienced facilitators, covering topics like team engagement, psychological safety, and facilitation techniques."
        },
        {
            question: "What happens if I need more than 5 questions per day?",
            answer: "You can upgrade to our Premium plan at any time to get unlimited question generations and access to all features."
        }
    ];

    return (
        <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
            {error && (
                <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-xl text-red-600">
                    {error}
                </div>
            )}

            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-4">

                {/* Premium Plan Details */}
                <div className="space-y-8">
                    <div>
                        <h1 className="text-3xl font-bold">Premium Plan</h1>
                        <p className="text-gray-600 mt-2">Everything you need to create engaging team moments. Check-in Questions Premium is your all-in-one solution for creating engaging team moments and fostering psychological safety.</p>
                    </div>

                    {/* New Features List */}
                    <div className="flex-1 space-y-6">

                        <ul className="space-y-3">
                            <li className="flex items-center">
                                <span className="mr-3">✨</span>
                                Unlimited access to our AI-powered question generator
                            </li>
                            <li className="flex items-center">
                                <span className="mr-3">🎯</span>
                                Advanced energizer activities and team exercises
                            </li>
                            <li className="flex items-center">
                                <span className="mr-3">📈</span>
                                Access to top-rated questions from our global community
                            </li>
                            <li className="flex items-center">
                                <span className="mr-3">🌐</span>
                                Monthly online workshops on facilitation best practices
                            </li>
                            <li className="flex items-center">
                                <span className="mr-3">🌐</span>
                                Join our global community of facilitators and team leaders
                            </li>
                        </ul>

                        <div className="bg-gray-100 rounded-xl p-6 space-y-3">
                            <p className="font-semibold">Perfect for team leaders, facilitators, and managers who want to:</p>
                            <ul className="space-y-2 opacity-90">
                                <li>• Create more meaningful team connections</li>
                                <li>• Learn new facilitation techniques</li>
                                <li>• Share experiences with peers globally</li>
                                <li>• Stay updated with latest team engagement practices</li>
                            </ul>
                        </div>
                    </div>

                {/* Current Plan Status */}
                <div className="flex items-center space-x-3 mb-8">
                    <div className="h-3 w-3 bg-green-500 rounded-full"></div>
                    <p className="text-gray-600">You're currently on the Free plan</p>
                </div>
                
                    {/* Price Display */}
                    <div className="text-center">
                        <div className="flex items-center justify-center">
                            <span className="text-5xl font-bold">${isAnnual ? '50' : '5'}</span>
                            <span className="text-gray-500 ml-2">/{isAnnual ? 'year' : 'month'}</span>
                        </div>
                    </div>

                    {/* Pricing Toggle */}
                    <div className="flex items-center justify-center space-x-4">
                        <span className={`text-sm ${!isAnnual ? 'text-blue-600 font-medium' : 'text-gray-500'}`}>
                            Monthly
                        </span>
                        <button
                            onClick={() => setIsAnnual(!isAnnual)}
                            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                                isAnnual ? 'bg-blue-600' : 'bg-gray-200'
                            }`}
                        >
                            <span
                                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                                    isAnnual ? 'translate-x-6' : 'translate-x-1'
                                }`}
                            />
                        </button>
                        <span className={`text-sm ${isAnnual ? 'text-blue-600 font-medium' : 'text-gray-500'}`}>
                            Annually (Save 17%)
                        </span>
                    </div>
                    {/* Subscribe Button */}
                    <button
                        onClick={() => handleSubscribe(isAnnual ? prices.yearly : prices.monthly)}
                        disabled={loading}
                        className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-xl text-lg transition duration-200 ease-in-out transform hover:translate-y-[-2px] disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
                    >
                        {loading ? (
                            <div className="flex items-center justify-center">
                                <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </div>
                        ) : (
                            'Upgrade to Premium'
                        )}
                    </button>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-6">
                <h2 className="text-3xl font-bold mb-8">Frequently asked questions</h2>
                <div className="max-w-3xl mx-auto">
                    <dl className="space-y-8 divide-y divide-gray-200">
                        {faqs.map((faq, index) => (
                            <div key={index} className={index === 0 ? 'pt-0' : 'pt-8'}>
                                <dt className="text-lg font-semibold text-gray-900 mb-4">
                                    {faq.question}
                                </dt>
                                <dd className="text-base text-gray-500">
                                    {faq.answer}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>

            {/* Footer Section */}
            <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-6">
                <div className="grid md:grid-cols-2 gap-8">
                    {/* Why Teams Choose Us */}
                    <div>
                        <h2 className="text-xl font-semibold mb-4">✨ Why Teams Choose Us</h2>
                        <ul className="space-y-3 text-gray-700">
                            <li className="flex items-start">
                                <span className="mr-3">🤝</span>
                                Build psychological safety and trust
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">🎯</span>
                                Increase meeting engagement
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">🌈</span>
                                Create inclusive team environments
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">🚀</span>
                                Accelerate team development
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">🌐</span>
                                Perfect for remote & hybrid teams
                            </li>
                        </ul>
                    </div>

                    {/* Perfect For */}
                    <div>
                        <h2 className="text-xl font-semibold mb-4">💫 Perfect For</h2>
                        <ul className="space-y-3 text-gray-700">
                            <li className="flex items-start">
                                <span className="mr-3">👥</span>
                                Team Leaders & Managers
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">⚡️</span>
                                Agile Coaches & Scrum Masters
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">📚</span>
                                Educators & Facilitators
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">💡</span>
                                Innovation Teams
                            </li>
                            <li className="flex items-start">
                                <span className="mr-3">🤗</span>
                                Community Leaders
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
} 